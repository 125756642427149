const itemLimits = {
  product: {
    attributesPreviewLimit: 7,
  },
  category: {
    paginationSizeList: [20, 40, 60],
    secretSizeLimit: 13931,
  },
  defaultSearchGroupSize: 5,
  defaultMinPhraseLength: 2,
  defaultDebounceTime: 300,
  defaultInvoiceRefreshTime: 3000,
  defaultBannerOptionRefreshTime: 5000,
  defaultBannerOptionAnimationTime: 300,
  defaultAutoMoveAnimationTime: 15000,
  defaultModalAnimationTime: 300,
  resizeDetectorRefreshRate: 500,
  defaultAuthorizationTimeout: 300000,
  defaultMainLevelFilterNodesLimit: 4,
  acceptableFilterNodesLimit: 6,
  quickFiltersSearchNodesLimit: 10,
  configurationOptionsLimit: 10,
  defaultGradientHeight: 64,
  defaultMapZoom: 10,
  scrollMapWithZoom: true,
  defaultMaxQuantity: 20,
  lastSeenItemsMaxCount: 8,
  lastSearchedItemsMaxCount: 20,
  nestedAttributesMaxLevel: 1,
  categoryBreadcrumbsMaxLevel: 3,
  breadcrumbsMaxOptions: 5,
  ordersLimit: 10,
  menuColumnLimit: 5,
  menuBoxElementsLimit: 3,
  articleRecommededVariantsLimit: 6,
  aliasVariantsLimit: 10,
  maxVariantRating: 5,
  nestedProductsLimit: 3,
};

export default itemLimits;
