import { ReactNode } from 'react';

import { DescriptionDataContext, DescriptionDataContextType } from './DescriptionDataProvider.context';

const { Provider } = DescriptionDataContext;

type Props = Partial<DescriptionDataContextType> & {
  children: ReactNode;
};

const DescriptionDataProvider = ({
  media = [],
  variants = [],
  products = [],
  categories = [],
  cardVariants = [],
  searchAliases = [],
  aliasVariants = [],
  productVariants = [],
  descriptionArticles = [],
  descriptionArticlesPhotos = [],
  descriptionArticlesVideos = [],
  children,
}: Props): JSX.Element => (
  <Provider
    value={{
      media,
      variants,
      products,
      categories,
      cardVariants,
      searchAliases,
      aliasVariants,
      productVariants,
      descriptionArticles,
      descriptionArticlesPhotos,
      descriptionArticlesVideos,
    }}
  >
    {children}
  </Provider>
);

export default DescriptionDataProvider;
